import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Comment Routing`}</h1>
    <p>{`Polis shows one comment at a time. The process of `}<a parentName="p" {...{
        "href": "/comment-routing",
        "title": "comment routing"
      }}>{`comment routing`}</a>{` determines which `}<a parentName="p" {...{
        "href": "/comment",
        "title": "comment"
      }}>{`comment`}</a>{` is chosen `}<a parentName="p" {...{
        "href": "/semi-randomly",
        "title": "semi-randomly"
      }}>{`semi-randomly`}</a>{` by the `}<a parentName="p" {...{
        "href": "/server",
        "title": "server"
      }}>{`server`}</a>{` and `}<a parentName="p" {...{
        "href": "/Math-Module",
        "title": "Math Module"
      }}>{`Math Module`}</a>{` to be displayed in the `}<a parentName="p" {...{
        "href": "/Participation-Interface",
        "title": "Participation Interface"
      }}>{`Participation Interface`}</a>{` to a `}<a parentName="p" {...{
        "href": "/participant",
        "title": "participant"
      }}>{`participant`}</a>{` next.`}</p>
    <p>{`This helps scale the system by making it more adaptive (see: `}<a parentName="p" {...{
        "href": "/Wikisurvey",
        "title": "Wikisurvey"
      }}>{`Wikisurvey`}</a>{`), while ensuring it does not overfit to what it already knows.`}</p>
    <h3>{`Intuition`}</h3>
    <p>{`In plain language, `}<a parentName="p" {...{
        "href": "/comments",
        "title": "comments"
      }}>{`comments`}</a>{` are prioritized according to:`}</p>
    <ul>
      <li parentName="ul">{`how predictive they are regarding where `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{` fall in the `}<a parentName="li" {...{
          "href": "/opinion-landscape",
          "title": "opinion landscape"
        }}>{`opinion landscape`}</a>{``}</li>
      <li parentName="ul">{`how likely they are to foster `}<a parentName="li" {...{
          "href": "/consensus",
          "title": "consensus"
        }}>{`consensus`}</a>{``}</li>
      <li parentName="ul">{`how new they are to the `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` (in order to allow newer comments to "bubble up" in established `}<a parentName="li" {...{
          "href": "/conversations",
          "title": "conversations"
        }}>{`conversations`}</a>{`), which gives comments decay (Not dissimilar to `}<a parentName="li" {...{
          "href": "https://medium.com/hacking-and-gonzo/how-hacker-news-ranking-algorithm-works-1d9b0cf2c08d"
        }}>{`Hacker News' conceptualization of "Gravity" for post position`}</a>{` which includes a time factor)`}</li>
      <li parentName="ul">{`how frequently `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` are `}<a parentName="li" {...{
          "href": "/pass",
          "title": "pass"
        }}>{`pass`}</a>{`ed on (suggestive of unclear phrasing, irrelevance or lack of focus), though there is active discussion as to whether expert opinion is also likely to be passed because it uses technical language`}</li>
    </ul>
    <h3>{`Mathematically speaking`}</h3>
    <p>{`The weight is deterministic. See: `}<a parentName="p" {...{
        "href": "/Representative-Comments",
        "title": "Representative Comments"
      }}>{`Representative Comments`}</a>{``}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      